import React, { useState } from "react";
import styles from "./ModuleAccordion.module.scss";
import ModulEachAccordian from "./ModuleEachAccordion";

function ModuleAccordian( props ) {
    const [openIndex, setOpenIndex] = useState(null);
    const {accordianItems}=props
   
    return (
        <div className={styles.accordian}>
                <hr className={styles.horozontalLine} />
                {accordianItems && accordianItems.length>0 ?
                 accordianItems.map((ele, index) => {
                    return (
                        <div key={index}>
                            <ModulEachAccordian
                               index={index}
                               _id={ele._id}
                               contentTypeId={ele._contentTypeId}
                               headerText={ele.headerText}
                               summaryText={ele.summaryText}
                               summaryRichText={ele.summaryRichText}
                               key={ele._id}
                               openIndex={openIndex}
                               setOpenIndex={setOpenIndex}/>
                           
                        </div>
                    );
                }):null}
        </div>
    );
}

export default ModuleAccordian;
