import React from 'react';
import sidekick from '@last-rev/contentful-sidekick-util';
import SVG from 'react-inlinesvg';
import isEmpty from 'lodash/isEmpty';
import cx from 'clsx';
import Image from 'next/image';
import styles from './Asset.module.scss';
import AssetPropTypes from './AssetPropTypes';
import imageLoader from './imageLoader';

function Asset({
  _id,
  /* filename, */
  /* size, */
  height,
  title,
  description,
  url,
  width,
  fieldName,
  imageWrapClassName,
  imageClassName,
  isInlineSvg,
  preload,
  bypassOptimization
}) {
  let alt;

  if (!isEmpty(description)) {
    alt = description;
  } else if (!isEmpty(title)) {
    alt = title;
  } else alt = '';

  let imgTitle = null;
  if (!isEmpty(title)) {
    imgTitle = title;
  } else if (!isEmpty(description)) {
    imgTitle = description;
  } else imgTitle = url;

  const showSvg = isInlineSvg !== null ? isInlineSvg : url && url.includes('.svg');

  return (
    <div
      data-testid="Asset"
      className={cx(
        showSvg ? 'inline-svg' : null,
        styles.imageWrap,
        styles[imageWrapClassName],
        imageWrapClassName
      )}
      {...sidekick(_id, fieldName, null, `${showSvg ? 'Inline SVG' : 'Image'}: ${imgTitle}`)}>
      {showSvg ? (
        <SVG
          data-testid="AssetSvg"
          src={url}
          title={alt}
          className={cx(styles.image, styles[imageClassName], imageClassName)}
          focusable="false"
          aria-label={alt}
          uniquifyIDs
        />
      ) : bypassOptimization ? (
        <img src={url} className={cx(styles.image, styles[imageClassName], imageClassName)} alt={alt} />
      ) : (
        <div style={{ maxHeight: height, maxWidth: width, margin: '0 auto', height:'100%' }}>
          <Image
            width={width}
            height={height}
            className={cx(styles.image, styles[imageClassName], imageClassName)}
            alt={alt}
            priority={preload}
            layout="responsive"
            loading={preload ? 'eager' : 'lazy'}
            loader={imageLoader}
            src={url}
          />
        </div>
      )}
    </div>
  );
}

Asset.propTypes = AssetPropTypes;

Asset.defaultProps = {
  _id: null,
  fieldName: '',
  imageWrapClassName: null,
  imageClassName: 'imgFluid',
  filename: '',
  height: 0,
  width: 0,
  size: 0,
  isInlineSvg: null,
  title: '',
  description: '',
  preload: false,
  bypassOptimization: false
};

export default Asset;
