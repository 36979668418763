import React, { useState } from "react";

import styles from "./ModuleAccordion.module.scss";
import { SlArrowDown, SlArrowUp } from "react-icons/sl";
import { useRouter } from "next/router";
import RichTextParser from "../RichTextParser";
import ReactMarkdown from 'react-markdown';

function ModulEachAccordian({ _id, contentTypeId, headerText, summaryText, summaryRichText, key, index, openIndex, setOpenIndex }) {
 
    const router = useRouter();
    const handleToggle = (index) => {
        setOpenIndex(openIndex === index ? null : index);
    }
    
    return (
        <div class={styles._id}>
            <div >
                <div className={styles.wrapcontainer} onClick={() => handleToggle(index)}>
                    <h5 className={styles.headertext}>{headerText}</h5>
                    <button className={router?.query?.contentType === "pageVideo" ? styles.newarrowbutton : styles.arrowbutton}>
                        {openIndex === index ? <SlArrowUp /> : <SlArrowDown />}
                    </button>
                </div>

                {
                    openIndex === index && (
                        <div className={styles.slideSummaryText} >
                            {summaryRichText ? (
                                <RichTextParser document={summaryRichText} />
                            ) : (
                                <ReactMarkdown>{summaryText}</ReactMarkdown>
                            )}
                        </div>
                        // <p className={styles.summarytext}>{ele.summaryText}</p>
                    )

                }
            </div>
            <hr />
        </div>

    )




}






export default ModulEachAccordian